<template>
  <div
    class="mx-auto w-full sm:mt-16 mt-8 sm:block flex"
  >
    <AtomsDashboardAuthSuccessScreen v-if="successScreen" @load-dashboard="psLogin" />
    <div v-else class="flex flex-col w-full">
      <h1 class="text-dark sm:text-xl text-lg">
        <strong class="font-bold">{{ userInformation.name }}, let’s set up your account</strong>
      </h1>
      <div class="py-2 mb-4 text-sm text-gray-80">
        {{ userInformation.email }}
        <atoms-popover-v2
          class="inline-flex align-middle"
          theme="default"
          position="bottom"
          has-close
          action-type="click"
          small-close
          radius="md"
        >
          <div>
            <IconsInfoCircle
              class="pointer-events-none h-4 w-4"
              type="attention"
              fill="#193560"
            />
          </div>
          <template #content>
            <p class="text-xs text-dark font-normal">
              Contact customer support to update your email. <a :href="`tel:${contactNumber}`" class="font-bold underline cursor-pointer">Call
                {{ contactNumber }}</a>
            </p>
          </template>
        </atoms-popover-v2>
      </div>
      <organisms-form-generator
        class="flex h-full flex-col"
        :schema="schema"
        :model="model"
        button-grid="grid grid-cols-1 mt-auto"
        submit-btn-txt="CONFIRM"
        @submit="saveUser"
      />
      <p class="my-2 text-sm font-normal text-center text-dark">
        Already a member?
        <atoms-button
          id="auth_login_btn"
          class="p-0 !text-base !inline-flex"
          size="small"
          theme="tertiary"
          enable-tracking
          to="/login/"
        >
          Log in
        </atoms-button>
      </p>
      <div
        class="font-normal text-gray-80 text-xs text-center px-0 pb-8 leading-[1.125rem]"
      >
        By clicking ‘CONFIRM', I acknowledge that I have read and agree to the<br />
        <nuxt-link
          ref="terms_of_use"
          class="underline cursor-pointer"
          to="/terms-of-use/"
          target="_blank"
        >
          <strong>Terms of Use</strong>
        </nuxt-link>, the
        <nuxt-link
          ref="privacy_policy"
          to="/privacy-policy/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Privacy Policy</strong>
        </nuxt-link> and the
        <nuxt-link
          ref="collection_notice"
          to="/collection-notice/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Collection Notice</strong>
        </nuxt-link>.
      </div>
    </div>
  </div>
</template>

<script setup>
import { VERTICALS } from '~/utils/constants/verticals.constants'
import { VERTICAL } from '~/helpers/vertical.constants'

defineOptions({
  name: 'OrganismsAuthenticationPostSale'
})

const props = defineProps({
  userInformation: {
    type: Object,
    required: true,
    validator: value => {
      return value.name && value.email
    }
  }
})

const vaultStore = useVaultStore()
const authStore = useUserStore()
const route = useRoute()
const schema = ref({
  password: {
    type: 'password',
    label: 'Password',
    theme: 'default',
    fieldLabel: '<strong>Set your password here <span class="text-alert-error-stroke">*</span></strong>',
    validate: 'password',
    passwordMeter: true
  }
})
const model = ref({
  password: null
})

const successScreen = ref(false)

const contactNumber = VERTICALS[VERTICAL.HEALTH_INSURANCE].phoneNumber

const successScreenTimeout = ref(null)

/*
 * Save user on post sale
 * @param payload
 */
function saveUser (payload) {
  payload.formData.email = props.userInformation.email
  payload.formData.from = route.query.from || 'email'
  vaultStore.savePurchasedUser(payload).then(res => {
    if (res.user_id) {
      successScreen.value = true
      successScreenTimeout.value = setTimeout(() => {
        psLogin()
      }, 5000)
    }
  })
}

/*
 * Post sale auto login
 */
function psLogin () {
  clearTimeout(successScreenTimeout.value)
  authStore.login({
    formData: {
      email: props.userInformation.email,
      password: model.value.password
    },
    psSignUp: true
  })
}
</script>

<style scoped>

</style>
